import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoIosMenu, IoIosClose } from 'react-icons/io';
import { FaCog, FaFacebookF, FaInstagram, FaSun, FaMoon } from 'react-icons/fa';
import { getSettings } from './../../../services/settingsServices';
import './LinkNav.css';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [clubInfo, setClubInfo] = useState({
    logo: '',
    nom: '',
    facebook: '',
    instagram: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchClubSettings = async () => {
      try {
        const settings = await getSettings();
        const club = settings[0];
        setClubInfo({
          logo: club.logo_club,
          nom: club.nom_club,
          facebook: club.facebook_club,
          instagram: club.instagram_club,
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des paramètres du club:', error);
      }
    };

    fetchClubSettings();

    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      setIsDarkMode(true);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const handleHomeClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsOpen(false);
  };

  const toggleTheme = () => {
    const isDark = !isDarkMode;
    setIsDarkMode(isDark);
    document.documentElement.classList.toggle('dark', isDark);
    localStorage.setItem('theme', isDark ? 'dark' : 'light');
  };

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        scrolled ? 'bg-gradient-to-b from-neutral-800 to-neutral-500' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center text-4xl font-passionOne">
          <img
            src={clubInfo.logo}
            alt="Logo Club"
            className="h-10 w-10 mr-2 object-contain"
          />
          <button onClick={handleHomeClick} className="text-white focus:outline-none">
            {clubInfo.nom}
          </button>
        </div>
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            aria-label="Menu"
            className="text-white focus:outline-none relative"
          >
            <div
              className={`hamburger-menu ${isOpen ? 'open' : ''} transition-transform duration-300`}
            >
              {isOpen ? (
                <IoIosClose className="text-3xl" />
              ) : (
                <IoIosMenu className="text-3xl" />
              )}
            </div>
          </button>
        </div>
        <ul className="hidden lg:flex space-x-8 text-white items-center">
          <li>
            <button
              onClick={() => handleNavigate('/')}
              className="hover:text-gray-300 transition link-nav2"
            >
              Accueil
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigate('/club')}
              className="hover:text-gray-300 transition link-nav2"
            >
              Le Club
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigate('/horaires-tarifs')}
              className="hover:text-gray-300 transition link-nav2"
            >
              Horaires & Tarifs
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigate('/inscription')}
              className="hover:text-gray-300 transition link-nav2"
            >
              Inscription
            </button>
          </li>
          {clubInfo.facebook && (
            <li>
              <FaFacebookF
                className="text-xl cursor-pointer hover:text-blue-500 transition"
                onClick={() => window.open(clubInfo.facebook, '_blank')}
                title="Facebook"
              />
            </li>
          )}
          {clubInfo.instagram && (
            <li>
              <FaInstagram
                className="text-xl cursor-pointer hover:text-pink-500 transition"
                onClick={() => window.open(clubInfo.instagram, '_blank')}
                title="Instagram"
              />
            </li>
          )}
          <li>
            <button onClick={toggleTheme} className="focus:outline-none">
              {isDarkMode ? (
                <FaSun className="text-xl hover:text-yellow-400 transition" title="Mode Light" />
              ) : (
                <FaMoon className="text-xl hover:text-gray-400 transition" title="Mode Dark" />
              )}
            </button>
          </li>
          <li>
            <FaCog
              className="text-xl cursor-pointer hover:text-orange-400 transition"
              title="Espace Admin"
              onClick={() => handleNavigate('/login')}
            />
          </li>
        </ul>
      </div>

      {isOpen && (
        <div className="lg:hidden bg-neutral-500 text-white w-full px-4 py-6 absolute top-full right-0 z-50">
          <ul className="flex flex-col items-center space-y-4 font-passionOne text-4xl font-thin">
            <li>
              <button
                onClick={() => handleNavigate('/')}
                className="hover:text-orange-400 transition link-nav2"
              >
                Accueil
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavigate('/club')}
                className="hover:text-orange-400 transition link-nav2"
              >
                Le Club
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavigate('/horaires-tarifs')}
                className="hover:text-orange-400 transition link-nav2"
              >
                Horaires & Tarifs
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavigate('/inscription')}
                className="hover:text-orange-400 transition link-nav2"
              >
                Inscription
              </button>
            </li>
            <li className="flex space-x-6">
              {clubInfo.facebook && (
                <FaFacebookF
                  className="text-xl cursor-pointer hover:text-orange-400 transition"
                  onClick={() => window.open(clubInfo.facebook, '_blank')}
                  title="Facebook"
                />
              )}
              {clubInfo.instagram && (
                <FaInstagram
                  className="text-xl cursor-pointer hover:text-orange-400 transition"
                  onClick={() => window.open(clubInfo.instagram, '_blank')}
                  title="Instagram"
                />
              )}
              <button onClick={toggleTheme} className="focus:outline-none">
                {isDarkMode ? (
                  <FaSun className="text-xl hover:text-orange-400 transition" title="Mode Light" />
                ) : (
                  <FaMoon className="text-xl hover:text-orange-400 transition" title="Mode Dark" />
                )}
              </button>
              <FaCog
                className="text-xl cursor-pointer hover:text-orange-400 transition"
                title="Espace Admin"
                onClick={() => handleNavigate('/login')}
              />
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
